'use strict';

$(document).ready(function () {
    if ($('.experience-commerce_layouts-accordionPanel .panel').length > 0) {
        var panels = $('.experience-commerce_layouts-accordionPanel .panel');
        var mainEntity = [];
        // eslint-disable-next-line no-plusplus
        for (var i = 0; i < panels.length; i++) {
            var panel = panels[i];
            var jsonQuestion = {};
            var html = panel.dataset.schemaText;
            var div = document.createElement('div');
            div.innerHTML = html;
            var text = div.textContent || div.innerText || '';
            jsonQuestion['@type'] = 'Question';
            jsonQuestion.name = panel.dataset.schemaName;
            jsonQuestion.acceptedAnswer = {
                '@type': 'Answer',
                text: text
            };
            mainEntity.push(jsonQuestion);
        }
        var faqSchemaJSON = {
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            mainEntity: mainEntity
        };
        $('#FAQScript').append(JSON.stringify(faqSchemaJSON));
    }

    var storepageDiv = $('div .storepage')[0];
    var divText = storepageDiv.dataset.schemaText;
    var divName = storepageDiv.dataset.schemaName;
    var articlePageURL = storepageDiv.dataset.schemaUrl;
    var homeURL = storepageDiv.dataset.schemaHome;
    var divImgSrc = $('.experience-main').find('.photo-tile-container').first('img').find('img')
        .attr('src');
    var articleSchemaJSON = {
        '@context': 'https://schema.org',
        '@type': 'Article',
        mainEntityOfPage: {
            '@type': 'WebPage',
            '@id': articlePageURL
        },
        headline: divName,
        description: divText,
        image: [
            divImgSrc
        ],
        author: {
            '@type': 'Organization',
            name: 'Cetaphil',
            url: homeURL
        },
        datePublished: ''
    };
    $('#ArticleScript').append(JSON.stringify(articleSchemaJSON));
});
